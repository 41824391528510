// React.
import { useState, useEffect, Fragment } from 'react'
// Router.
import { useParams } from "react-router-dom"
// Redux.
import { useDispatch, useSelector } from "react-redux"
// OrganicoSlice.
import {
    clearEmployeeCourseDocSenderId, deleteEmployeeCourseDocById,
    downloadEmployeeCourseDocById, getEmployeeCoursesById,
    clearEmployeeExamSenderId, deleteEmployeeExamById,
    downloadEmployeeExamById, getEmployeeExamsById,
    deleteEmployeeDocById, downloadEmployeeDocById, getEmployeeDocsById,
    getEmployeeImgById, getEmployeeInfoById,
    organicoSelector,
    showEmployeeCourseDocById,
    showEmployeeDocById,
    uploadEmployeeLogo,
    downloadOrganico,
    downloadEmployeeDetails, downloadEmployeeCourses, downloadEmployeeMedicalCheckups, downloadEmployeeDocuments
} from "../../features/generale/organico/OrganicoSlice"
// OrganicoModalSlice.
import {
    organicoModalSelector,
    openModal as openEditModal,
} from "../../features/generale/organico/OrganicoModalSlice"
// UI.
import LayoutDetailContainer from "../LayoutDetailContainer"
import LayoutDetail from '../LayoutDetail'
import SubHeader from "../../base/SubHeader"
import Input from "../../base/Input"
import Tabs from "../../base/Tabs"
import ImgUploader from "../../base/ImgUploader"
import Grid from "../../base/Grid"
import SearchBox from "../../base/SearchBox"
import DropDown from "../../base/DropDown"
import Button from "../../base/Button"
import OrganicoModal from "../generale/OrganicoModal"
import PdfViewer from "../../base/PdfViewer"
import CompliantCircle from "../../base/CompliantCircle"
// Icons.
import {
    PluSm,
    Printer,
    DocumentText,
    OvalLoader,
    InformationCircle,
    Screwdriver,
} from "../../base/Icons"

import OgranicoDetailSkeleton from "../../detail/organico/OgranicoDetailSkeleton"

import { corsoModalSelector, openModal as openCorsoModal } from "../../features/generale/organico/CorsoModalSlice"
import { documentoModalSelector, openModal as openDocumentoModal } from "../../features/generale/organico/DocumentoModalSlice"
import { visitaModalSelector, openModal as openVisitaModal } from "../../features/generale/organico/VisitaModalSlice"
import CorsoModal from "../../detail/generale/CorsoModal"
import DocumentoModal from "../../detail/generale/DocumentoModal"
import VisitaModal from "../../detail/generale/VisitaModal"
import Tooltip from "../../base/Tooltip"
// SearchSlice.
import { searchSelector, setSearchList, reset } from "../../features/search/SearchSlice"

const DocsGridDefaultStructure = [
    {
        text: "",
        twColSpan: "col-span-1",
        twAdditionalClass: "",
    },
    {
        text: "Documento",
        additionalText: "",
        twColSpan: "col-span-3",
        twAdditionalClass: "",
    },
    {
        text: "Data",
        twColSpan: "col-span-2",
        twAdditionalClass: "",
    },
    {
        text: "Data Scadenza",
        twColSpan: "col-span-2",
        twAdditionalClass: "",
    },
    {
        text: "Allegato",
        twColSpan: "col-span-1",
        twAdditionalClass: "",
    },
    {
        text: "Convalidato",
        twColSpan: "",
        twAdditionalClass: "justify-self-center",
    },
    {
        text: "",
        twColSpan: "col-span-2",
        twAdditionalClass: "justify-self-end",
    },
];

const CorsiColMap = (col, colIdx) => {
    switch (colIdx) {
        // Column Titolo
        case 1:
            return { ...col, text: "Corso" };
        // Column Data corso   
        case 2:
            return { ...col, text: "Data corso" };
    }
    return { ...col }
};

const VisiteColMap = (col, colIdx) => {
    switch (colIdx) {
        // Column Titolo
        case 1:
            return { ...col, text: "Visita", twColSpan: "col-span-3" };
        // Column Data visita   
        case 2:
            return { ...col, text: "Data visita" };
        // Column Convalidato
        case 5:
            return { ...col, twColSpan: "col-span-3" };
    }
    return { ...col }
};

const DocumentColMap = (col, colIdx) => {
    switch (colIdx) {
        // Column Titolo
        case 1:
            return { ...col, text: "Documento" };
        // Column Data rilascio   
        case 2:
            return { ...col, text: "Data rilascio" };
    }
    return { ...col }
};

const DocHeaderColMap = (col, colIdx) => {
    const clone = { ...col };
    clone.title = clone.text;
    delete clone.text;
    switch (colIdx) {
        case 6:
            // Column Actions
            clone.component = <SearchBox />;
            break;
    }
    return clone
};

const VisiteHeaderColMap = (col, colIdx) => {
    const clone = { ...col };
    clone.title = clone.text;
    delete clone.text;
    switch (colIdx) {
        case 4:
            break;
        case 5:
            // Column Actions
            clone.twColSpan = "col-span-3";
            clone.component = <SearchBox />;
            break;
    }
    return clone
};

const DataScadenzaSort = (a, b) => {
    if (typeof a.data_scadenza !== 'string') {
        return false
    };
    const scadenzaAParts = a.data_scadenza.split("/");
    if (scadenzaAParts.length < 3) {
        return -1
    };
    const scadenzaAObj = new Date(parseInt(scadenzaAParts[2]), parseInt(scadenzaAParts[1]) - 1, parseInt(scadenzaAParts[0]));
    const scadenzaBParts = b.data_scadenza.split("/");
    if (scadenzaBParts.length < 3) {
        return 1
    };
    const scadenzaBObj = new Date(parseInt(scadenzaBParts[2]), parseInt(scadenzaBParts[1]) - 1, parseInt(scadenzaBParts[0]));
    return scadenzaAObj > scadenzaBObj ? -1 : 1
};

const DocumentItemIconComponent = (
    <div
        className="
        flex 
        space-x-0.5 sm:space-x-1 md:space-x-2 lg:space-x-3 xl:space-x-4 2xl:space-x-5
        justify-self-start 
        text-afm-box-light-gray-text"
    >
        <DocumentText />
        <span className="border-l border-afm-box-light-gray-text"></span>
    </div>
);

/**
 *
 * @dev Lavoratori detail view.
 * 
 * @function LavoratoriDetail
 *
 * @returns JSX.Element
 *
 */
export default function LavoratoriDetail() {
    const dispatch = useDispatch()
    // screen modals
    const corsoModalStatus = useSelector(corsoModalSelector)
    const documentoModalStatus = useSelector(documentoModalSelector)
    const visitaModalStatus = useSelector(visitaModalSelector)
    // visuraModalSlice State.
    const { modalIsOpen, modalIsSuccess } = useSelector(organicoModalSelector);
    const [currentCategory, setCurrentCategory] = useState('informazioni');

    // searchSlice State.
    const { searchResults, searchList, searchQuery } = useSelector(searchSelector);

    // Tab categories.
    const [categories, setCategories] = useState({})
    // Corsi grid header title.
    const [corsiGridHeaderTitleItems, setCorsiGridHeaderTitleItems] = useState([])
    // Corsi grid items.
    const [corsiGridItems, setCorsiGridItems] = useState([])
    // Visite grid header title.
    const [visiteGridHeaderTitleItems, setVisiteGridHeaderTitleItems] = useState([])
    // Visite grid items.
    const [visiteGridItems, setVisiteGridItems] = useState([])
    // Documenti grid header title.
    const [documentiGridHeaderTitleItems, setDocumentiGridHeaderTitleItems] = useState([])
    // Documenti grid items.
    const [documentiGridItems, setDocumentiGridItems] = useState([])
    const [modalComponent, setModalComponent] = useState(null);
    // organicoSlice State.
    const {
        employeeImg,
        employeeInfo,
        employeeCourses,
        employeeExams,
        employeeDocs,
        isFetching,
        isSuccess,
        isError,
        employeeCourseDoc,
        employeeCourseDocSenderId,
        isFetchingCourseDoc,
        isSuccessCourseDoc,
        defaultTabIndex,
        // employeeExam,
        employeeExamSenderId,
        isFetchingExam,
        // isSuccessExam,
    } = useSelector(organicoSelector)

    // Status Class enums can be grouped as static members of a class.
    class Status {
        // Create new instances of the same class as static attributes.
        //
        // compliant namespace.
        static compliant = new Status("compliant", "Convalidato")
        // nonCompliant namespace.
        static nonCompliant = new Status("non-compliant", "Non convalidato")
        // waiting namespace.
        static waiting = new Status("waiting", "In attesa di verifica")

        constructor(value, message) {
            this.value = value
            this.message = message
        }
    }

    /**
      *
      * @const Circle
      *
      * @dev Circle UI component.
      *
      * @param { string } message
      *
      * @returns JSX.Element
      *
      */
    const Circle = (props) => {
        /**
         * getStatus function.
         * It return the TailwindCSS class token as the corresponding status.
         */
        const getStatus = (status) => {
            switch (status) {
                case "compliant":
                    return "bg-afm-box-success-green"

                case "non-compliant":
                    return "bg-afm-box-error-red"

                case "waiting":
                    return "bg-afm-box-waiting-yellow"

                default:
                    return "bg-afm-box-light-gray"
            }
        }
        return (
            <Tooltip message={props.message} twBackground="bg-afm-box-blue">
                <div
                    className={`
                            rounded-full 
                            h-3 
                            w-3 
                            ${getStatus(props.status)}`}
                ></div>
            </Tooltip>
        )
    }

    // Get the url parameter.
    const { id } = useParams()

    /**
     * Component side effects.
     */
    useEffect(() => {
        dispatch(getEmployeeImgById(id))
        dispatch(getEmployeeInfoById(id))
        dispatch(getEmployeeCoursesById(id))
        dispatch(getEmployeeExamsById(id))
        dispatch(getEmployeeDocsById(id))
    }, [dispatch, id]);

    useEffect(() => {
        if (modalIsSuccess === true) {
            // dispatch(getEmployeeInfoById(id))
            window.location.reload();
        }
    }, [
        modalIsSuccess
    ]);

    useEffect(() => {
        const corsiItems = [];
        const emptySearch = (searchQuery || '').trim() === "";
        const results = emptySearch ? searchList : searchResults;
        const items = currentCategory === 'corsi' ? results : employeeCourses;
        const gridStructure = DocsGridDefaultStructure.map(CorsiColMap);
        if (items.length === 0) {
            setCorsiGridItems(items);
        }
        items
            .map(item => ({ ...item }))
            .sort(DataScadenzaSort)
            .map((course) => {
                const valoreConvalidato = course?.convalidato == 'Si';
                const gridItem = gridStructure.map(item => ({ ...item }));
                // Column 1
                gridItem[0].component = DocumentItemIconComponent;
                // Column Titolo
                gridItem[1].text = course.titolo;
                // Column Data attestato
                gridItem[2].text = course.data_attestato;
                // Column Data scadenza
                gridItem[3].text = course.data_scadenza;
                // Column Allegato
                gridItem[4].component = <PdfViewer action={showEmployeeCourseDocById(course.id_box_corsi_lavoratore)} />;
                // Column Convalidato
                gridItem[5].component = <CompliantCircle status={valoreConvalidato} />;
                // Column Actions
                gridItem[6].component = (<div className="flex items-center space-x-2">
                    {course.allegato && (
                        <>
                            {/* If isFetchingCourseDoc and employeeCourseDocSenderId State correspond to Button id pressed, than show the OvalLoader. */}
                            {isFetchingCourseDoc &&
                                employeeCourseDocSenderId === course.id_box_corsi_lavoratore && (
                                    <OvalLoader strokeColor="#aaa" />
                                )}
                            {(course?.convalidato == 'Si') &&
                                <Button
                                    text="DOWNLOAD"
                                    id={course.id_box_corsi_lavoratore}
                                    twTextColor="text-white"
                                    twBackground="bg-afm-box-blue"
                                    twFocusBG="focus:bg-afm-box-blue/[0.5]"
                                    actions={[
                                        clearEmployeeCourseDocSenderId(),
                                        downloadEmployeeCourseDocById(
                                            {
                                                idBoxCorsiLavoratore: course.id_box_corsi_lavoratore,
                                                docName: course.allegato
                                            }
                                        ),
                                    ]}
                                />
                            }
                            {/* Only Admin can remove docs. */}
                            {employeeInfo.id_origine === "0" &&
                                <DropDown
                                    buttonItems={[
                                        {
                                            type: "delete",
                                            method: deleteEmployeeCourseDocById({
                                                idBoxLavoratori: id,
                                                idBoxCorsiLavoratore: course.id_box_corsi_lavoratore
                                            })
                                        }
                                    ]}
                                />
                            }
                        </>
                    )}
                </div>);
                corsiItems.push(gridItem);
                return setCorsiGridItems(corsiItems);
            });
        setCorsiGridHeaderTitleItems(gridStructure.map(DocHeaderColMap));
    }, [
        searchList,
        searchQuery,
        searchResults
    ]);

    useEffect(() => {
        /**
         * Build "Visite" category grid items.
         */
        const visiteItems = [];
        const emptySearch = (searchQuery || '').trim() === "";
        const results = emptySearch ? searchList : searchResults;
        const items = currentCategory === 'visite' ? results : employeeExams;
        const gridStructure = DocsGridDefaultStructure.filter((col, colIdx) => (colIdx !== 4)).map(VisiteColMap);
        if (items.length === 0) {
            setVisiteGridItems(items);
        }
        items
            .map(item => ({ ...item }))
            .sort(DataScadenzaSort)
            .map((exam) => {
                const valoreConvalidato = exam?.convalidato == 'Si';
                const gridItem = gridStructure.map(item => ({ ...item }));
                // Column 1
                gridItem[0].component = DocumentItemIconComponent;
                // Column Titolo
                gridItem[1].text = exam?.titolo ? exam.titolo : exam.codice_visita;
                // Column Data attestato
                gridItem[2].text = exam.data_visita;
                // Column Data scadenza
                gridItem[3].text = exam.data_scadenza;
                // Column Convalidato
                gridItem[4].component = <CompliantCircle status={valoreConvalidato} />;
                // Column Actions
                gridItem[5].component = (<div className="flex space-x-2">
                    {exam.allegato && (
                        <>
                            {/* If isFetchingExam and employeeExamSenderId State correspond to Button id pressed, than show the OvalLoader. */}
                            {isFetchingExam && employeeExamSenderId === exam.id_box_visite_lavoratore && (
                                <OvalLoader strokeColor="#aaa" />
                            )}
                            {(exam?.convalidato == 'Si') &&
                                <Button
                                    text="DOWNLOAD"
                                    id={exam.id_box_visite_lavoratore}
                                    twTextColor="text-white"
                                    twBackground="bg-afm-box-blue"
                                    twFocusBG="focus:bg-afm-box-blue/[0.5]"
                                    actions={[
                                        clearEmployeeExamSenderId(),
                                        downloadEmployeeExamById(
                                            {
                                                idBoxVisitaLavoratore: exam.id_box_visite_lavoratore,
                                                docName: exam.allegato
                                            }
                                        ),
                                    ]}
                                />
                            }
                            {/* Only Admin can remove docs. */}
                            {employeeInfo.id_origine === "0" &&
                                <DropDown
                                    buttonItems={[
                                        {
                                            type: "delete",
                                            method: deleteEmployeeExamById({
                                                idBoxLavoratori: id,
                                                idBoxVisitaLavoratore: exam.id_box_visite_lavoratore
                                            })
                                        }
                                    ]}
                                />
                            }
                        </>
                    )}
                </div>);
                visiteItems.push(gridItem)
                return setVisiteGridItems(visiteItems)
            });
        setVisiteGridHeaderTitleItems(gridStructure.map(VisiteHeaderColMap));
    }, [
        searchList,
        searchQuery,
        searchResults
    ]);

    useEffect(() => {
        /**
         * Build Grid items for Documenti category. 
         */
        const documentiItems = [];
        const emptySearch = (searchQuery || '').trim() === "";
        const results = emptySearch ? searchList : searchResults;
        const items = currentCategory === 'documenti' ? results : employeeDocs;
        const gridStructure = DocsGridDefaultStructure.map(DocumentColMap);
        if (items.length === 0) {
            setDocumentiGridItems(items);
        }
        items
            .map(item => ({ ...item }))
            .sort(DataScadenzaSort)
            .map((doc) => {
                const valoreConvalidato = doc?.convalidato == 'Si';
                const gridItem = gridStructure.map(item => ({ ...item }));
                // Column 1
                gridItem[0].component = DocumentItemIconComponent;
                // Column Titolo
                gridItem[1].text = doc.tipo;
                // Column Data attestato
                gridItem[2].text = doc.data_documento;
                // Column Data scadenza
                gridItem[3].text = doc.data_scadenza;
                // Column Allegato
                gridItem[4].component = <PdfViewer action={showEmployeeDocById(doc.id_box_documenti_lavoratore)} />;
                // Column Convalidato
                gridItem[5].component = <CompliantCircle status={valoreConvalidato} />;
                // Column Actions
                gridItem[6].component = (
                    <div className="flex space-x-2">
                        {(doc?.convalidato == 'Si') &&
                            <Button
                                text="download"
                                twTextColor="text-white"
                                twBackground="bg-afm-box-blue"
                                twFocusBG="focus:bg-afm-box-blue/[0.5]"
                                actions={[
                                    clearEmployeeCourseDocSenderId(),
                                    downloadEmployeeDocById(
                                        {
                                            idBoxDocumentiLavoratore: doc.id_box_documenti_lavoratore,
                                            docName: doc.allegato
                                        }
                                    ),
                                ]}
                            />
                        }
                        {/* Only Admin can remove docs. */}
                        {employeeInfo.id_origine === "0" &&
                            <DropDown
                                buttonItems={[
                                    {
                                        type: "delete",
                                        method: deleteEmployeeDocById({
                                            idBoxLavoratori: id,
                                            idBoxDocumentiLavoratore: doc.id_box_documenti_lavoratore
                                        })
                                    }
                                ]}
                            />
                        }
                    </div>
                );
                documentiItems.push(gridItem)
                return setDocumentiGridItems(documentiItems)
            });
        setDocumentiGridHeaderTitleItems(gridStructure.map(DocHeaderColMap));
    }, [
        searchList,
        searchQuery,
        searchResults
    ]);

    useEffect(() => {
        if (isSuccess) {
            // if dataItems are been fetched then call setSearchList giving:
            // list, search key and an additional list of search key
            const searchConfig = {
                dataItems: employeeCourses,
                searchIndex: 'titolo',
                additionalIndex: [],
            };
            if (currentCategory === 'corsi' && employeeCourses.length <= 0) {
                dispatch(reset());
                return
            }
            if (currentCategory === 'visite' && employeeExams.length <= 0) {
                dispatch(reset());
                return
            }
            if (currentCategory === 'documenti' && employeeDocs.length <= 0) {
                dispatch(reset());
                return
            }
            switch (currentCategory) {
                case 'visite':
                    searchConfig.dataItems = employeeExams;
                    break;
                case 'documenti':
                    searchConfig.dataItems = employeeDocs;
                    searchConfig.searchIndex = 'tipo';
                    break;
            };
            dispatch(
                setSearchList(searchConfig)
            );
        }
    }, [
        searchQuery,
        employeeCourses,
        employeeExams,
        employeeDocs,
        currentCategory
    ]);

    useEffect(() => {
        /**
         *
         *  Build Tab categories.
         *
         */
        const categoryItems = {
            Informazioni: [
                {
                    id: 1,
                    component: (
                        <Fragment>
                            <div
                                className="
                            h-16 
                            items-center 
                            flex 
                            space-x-8 
                            p-3 
                            rounded-sm 
                            bg-gray-100"
                            >
                                <h1 className="text-left text-lg font-bold">Informazioni</h1>
                            </div>
                            <div className="flex gap-10 pt-10">
                                <div className="relative">
                                    <ImgUploader
                                        img={employeeImg}
                                        action={uploadEmployeeLogo}
                                        id={employeeInfo.id_box_lavoratori}
                                        label={'foto lavoratore'}
                                    />
                                </div>
                                <form action="" method="POST" className="w-full pr-6">
                                    <div className="flex gap-10">
                                        <div className="flex-1">
                                            <Input
                                                labelHtmlFor="employee-name"
                                                labelTitle="Nome"
                                                inputType="text"
                                                inputName="employee-name"
                                                placeholder=""
                                                required={true}
                                                inputValue={employeeInfo.nome}
                                            />
                                        </div>
                                        <div className="flex-1">
                                            <Input
                                                labelHtmlFor="employee-surname"
                                                labelTitle="Cognome"
                                                inputType="text"
                                                inputName="employee-surname"
                                                placeholder=""
                                                required={true}
                                                inputValue={employeeInfo.cognome}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex gap-10">
                                        <div className="flex-1">
                                            <Input
                                                labelHtmlFor="employee-cf"
                                                labelTitle="Codice Fiscale"
                                                inputType="text"
                                                inputName="employee-cf"
                                                placeholder=""
                                                required={true}
                                                inputValue={employeeInfo.codice_fiscale}
                                            />
                                        </div>
                                        <div className="flex-1">
                                            <Input
                                                labelHtmlFor="employee-birth-date"
                                                labelTitle="Data di nascita"
                                                inputType="text"
                                                inputName="employee-birth-date"
                                                placeholder=""
                                                required={true}
                                                inputValue={employeeInfo.data_nascita}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex gap-10">
                                        <div className="flex-1">
                                            <Input
                                                labelHtmlFor="employee-birth-place"
                                                labelTitle="Luogo di nascita"
                                                inputType="text"
                                                inputName="employee-birth-place"
                                                placeholder={""}
                                                required={true}
                                                inputValue={employeeInfo.luogo_nascita}
                                            />
                                        </div>
                                        <div className="">
                                            <Input
                                                labelHtmlFor="employee-birth-place-province"
                                                labelTitle="Provincia"
                                                inputType="text"
                                                inputName="employee-birth-place-province"
                                                placeholder={""}
                                                required={true}
                                                inputValue={employeeInfo.provincia_nascita}
                                            />
                                        </div>
                                        <div className="flex-1">
                                            <Input
                                                labelHtmlFor="employee-nationality"
                                                labelTitle="Nazionalità"
                                                inputType="text"
                                                inputName="employee-nationality"
                                                placeholder={""}
                                                required={true}
                                                inputValue={employeeInfo.nazionalita}
                                            />
                                        </div>
                                    </div>
                                    <div className="my-10">
                                        <div className="flex gap-10">
                                            <div className="flex-1">
                                                <Input
                                                    labelHtmlFor="employee-hire-date"
                                                    labelTitle="Data di Assunzione"
                                                    inputType="text"
                                                    inputName="employee-hire-date"
                                                    placeholder=""
                                                    required={true}
                                                    inputValue={employeeInfo.data_assunzione}
                                                />
                                            </div>
                                            <div className="flex-1">
                                                <Input
                                                    labelHtmlFor="employee-type"
                                                    labelTitle="Tipologia Dipendente"
                                                    inputType="text"
                                                    inputName="employee-type"
                                                    placeholder=""
                                                    required={true}
                                                    inputValue={employeeInfo.tipo_dipendente}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex gap-10">
                                            <div className="flex-1">
                                                <Input
                                                    labelHtmlFor="employee-title"
                                                    labelTitle="Qualifica"
                                                    inputType="text"
                                                    inputName="employee-title"
                                                    required={true}
                                                    inputValue={employeeInfo.qualifica}
                                                />
                                            </div>
                                            <div className="flex-1">
                                                <Input
                                                    labelHtmlFor="employee-job"
                                                    labelTitle="Mansione"
                                                    inputType="text"
                                                    inputName="employee-job"
                                                    placeholder=""
                                                    required={true}
                                                    inputValue={employeeInfo.mansione}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex space-x-10">
                                        {/* <Button
                                        disabled={true}
                                        text="Salva modifiche"
                                        twTextColor="text-white"
                                        twBackground="bg-afm-box-blue"
                                        twFocusBG="focus:bg-afm-box-blue/[0.5]"
                                    /> */}
                                        {/* TODO: Wait API implementation.*/}
                                        {/*
                                    <Button
                                        disabled={true}
                                        text="Crea tesserino"
                                        twTextColor="text-white"
                                        twBackground="bg-afm-box-dark-gray"
                                        twFocusBG="focus:bg-afm-box-blue/[0.5]"
                                    />
                                */}
                                    </div>
                                </form>
                            </div>
                        </Fragment>
                    ),
                },
            ],
            Corsi: [
                {
                    id: 1,
                    component: (
                        <Fragment>
                            <div
                                className="
                                    h-16 
                                    items-center 
                                    flex 
                                    space-x-8 
                                    p-3 
                                    rounded-sm 
                                    bg-gray-100"
                            >
                                <h1 className="text-left text-lg font-bold flex">
                                    Corsi
                                    <div className="ml-2">
                                        <Tooltip
                                            message="Elenco dei corsi svolti in materia di salute e sicurezza previsti dalla normativa vigente"
                                            twBackground="bg-afm-box-blue"
                                            translateTooltip={true}
                                        >
                                            <InformationCircle />
                                        </Tooltip>
                                    </div>
                                </h1>
                                <div className="flex flex-1 justify-end space-x-5">
                                    {/*
                                    <Button
                                        text='Scarica tutto'
                                        icon={
                                                (isFetchingCourseDoc) &&
                                            <OvalLoader strokeColor='#aaa' />
                                        }
                                        twTextColor='text-black'
                                        twBackground='bg-white'
                                        twFocusBG='focus:bg-white/[0.7]'
                                        actions={[downloadEmployeeCourseDocById(id)]}
                                    />
                                    */}
                                    {/* <Button
                                        disabled={true}
                                        text="Scarica Tutto"
                                        twTextColor="text-black"
                                        twBackground="bg-white"
                                        twFocusBG="focus:bg-white/[0.7]"
                                    //icon={ }
                                    /> */}
                                    {/* <Button
                                        text="Aggiungi corso"
                                        twTextColor="text-white"
                                        twBackground="bg-afm-box-dark-gray"
                                        twFocusBG="focus:bg-afm-box-dark-gray/[0.5]"
                                        onClick={() => {
                                            dispatch(openCorsoModal());
                                        }}
                                        icon={<PluSm />}
                                    /> */}
                                    <Button
                                        text="Stampa"
                                        twTextColor="text-white"
                                        twBackground="bg-afm-box-dark-gray"
                                        twFocusBG="focus:bg-afm-box-dark-gray/[0.5]"
                                        actions={[downloadEmployeeCourses({ lavoratoreId: id })]}
                                        icon={<Printer />}
                                    />
                                </div>
                            </div>
                            <Grid
                                twGridTemplateColumns="grid-cols-12"
                                headerTitleItems={corsiGridHeaderTitleItems}
                                items={corsiGridItems}
                            />
                        </Fragment>
                    ),
                },
            ],
            Visite: [
                {
                    id: 1,
                    component: (
                        <Fragment>
                            <div
                                className="
                                    h-16 
                                    items-center 
                                    flex 
                                    space-x-8 
                                    p-3 
                                    rounded-sm
                                    bg-gray-100"
                            >
                                <h1 className="text-left text-lg font-bold">
                                    Visite
                                </h1>
                                <Tooltip
                                    message="È consentito inserire le visite mediche preventive e periodiche svolte dal medico competente."
                                    twBackground="bg-afm-box-blue"
                                >
                                    <InformationCircle />
                                </Tooltip>
                                <div className="flex flex-1 justify-end space-x-5">
                                    {/* <Button
                                        disabled={true}
                                        text="Scarica tutto"
                                        twTextColor="text-black"
                                        twBackground="bg-white"
                                        twFocusBG="focus:bg-white/[0.7]"
                                    /> */}
                                    {/* <Button
                                        text="Aggiungi visita"
                                        twTextColor="text-white"
                                        twBackground="bg-afm-box-dark-gray"
                                        twFocusBG="focus:bg-afm-box-dark-gray/[0.5]"
                                        onClick={() => {
                                            dispatch(openVisitaModal())
                                        }}
                                        icon={<PluSm />}
                                    /> */}
                                    <Button
                                        text="Stampa"
                                        twTextColor="text-white"
                                        twBackground="bg-afm-box-dark-gray"
                                        twFocusBG="focus:bg-afm-box-dark-gray/[0.5]"
                                        onClick={() => {
                                            // console.log('aggiungi corso')
                                            // setModalComponent(<CorsoModal id={id} />);
                                            // dispatch(openModal())
                                            dispatch(downloadEmployeeMedicalCheckups({ lavoratoreId: id }));
                                        }}
                                        icon={<Printer />}
                                    />
                                </div>
                            </div>
                            <Grid
                                twGridTemplateColumns="grid-cols-12"
                                headerTitleItems={visiteGridHeaderTitleItems}
                                items={visiteGridItems}
                            />
                            {/* If Modal window is open, then render. */}
                            {/* {modalIsOpen && <VisitaModal />} */}
                            {/* <VisitaModal /> */}

                        </Fragment>
                    ),
                },
            ],
            Documenti: [
                {
                    id: 1,
                    component: (
                        <Fragment>
                            <div
                                className="
                                    h-16 
                                    items-center 
                                    flex 
                                    space-x-8 
                                    p-3 
                                    rounded-sm
                                    bg-gray-100"
                            >
                                <h1 className="text-left text-lg font-bold">
                                    Documenti
                                </h1>
                                <Tooltip
                                    message="È consentito inserire i documenti relativi all’assunzione e lo svolgimento dell’attività."
                                    twBackground="bg-afm-box-blue"
                                >
                                    <InformationCircle />
                                </Tooltip>
                                <div className="flex flex-1 justify-end space-x-5">
                                    {/* <Button
                                        disabled={true}
                                        text="Scarica tutto"
                                        twTextColor="text-black"
                                        twBackground="bg-white"
                                        twFocusBG="focus:bg-white/[0.7]"
                                    /> */}
                                    {/* <Button
                                        disabled={false}
                                        text="Aggiungi doc"
                                        twTextColor="text-white"
                                        twBackground="bg-afm-box-dark-gray"
                                        twFocusBG="focus:bg-afm-box-dark-gray/[0.5]"
                                        icon={<PluSm />}
                                    /> */}
                                    {/* <Button
                                        text="Aggiungi documento"
                                        twTextColor="text-white"
                                        twBackground="bg-afm-box-dark-gray"
                                        twFocusBG="focus:bg-afm-box-dark-gray/[0.5]"
                                        onClick={() => {
                                            dispatch(openDocumentoModal())
                                        }}
                                        icon={<PluSm />}
                                    /> */}
                                    <Button
                                        text="Stampa"
                                        twTextColor="text-white"
                                        twBackground="bg-afm-box-dark-gray"
                                        twFocusBG="focus:bg-afm-box-dark-gray/[0.5]"
                                        onClick={() => {
                                            // console.log('aggiungi corso')
                                            // setModalComponent(<CorsoModal id={id} />);
                                            // dispatch(openModal())
                                            dispatch(downloadEmployeeDocuments({ lavoratoreId: id }));
                                        }}
                                        icon={<Printer />}
                                    />
                                </div>
                            </div>
                            <Grid
                                twGridTemplateColumns="grid-cols-12"
                                headerTitleItems={documentiGridHeaderTitleItems}
                                items={documentiGridItems}
                            />
                        </Fragment>
                    ),
                },
            ],
        };
        return setCategories(categoryItems)
    }, [
        employeeImg,
        employeeInfo.id_box_lavoratori,
        employeeInfo.codice_fiscale,
        employeeInfo.cognome,
        employeeInfo.mansione,
        employeeInfo.tipo_dipendente,
        employeeInfo.nome,
        employeeInfo.qualifica,
        corsiGridHeaderTitleItems,
        // corsiGridItems,
        visiteGridHeaderTitleItems,
        // visiteGridItems,
        documentiGridHeaderTitleItems,
        // documentiGridItems,
        isFetching,
        isSuccess,
        isError,
    ])

    useEffect(() => {
        if (corsoModalStatus.modalIsOpen === false) {
            dispatch(getEmployeeCoursesById(id))
        }
    }, [
        corsoModalStatus.modalIsOpen,
    ]);
    useEffect(() => {
        if (documentoModalStatus.modalIsOpen === false) {
            dispatch(getEmployeeDocsById(id))
        }
    }, [
        documentoModalStatus.modalIsOpen,
    ]);
    useEffect(() => {
        if (visitaModalStatus.modalIsOpen === false) {
            dispatch(getEmployeeExamsById(id))
        }
    }, [
        visitaModalStatus.modalIsOpen,
    ]);

    /**
     *
     * Component render.
     *
     */
    return (
        <LayoutDetail title="Lavoratori">
            <SubHeader
                subHeaderTitle="Lavoratori"
                needFirstBreadCrumb={true}
                firstBtnVisibile={true}
                firstBtnText="stampa tesserino"
                firstBtnIcon={<Printer />}
                firstBtnTwBg="bg-white"
                firstBtnTwFocusBg="focus:bg-white/[0.7]"
                firstBtnTwTextColor="text-black"
                firstBtnActions={[downloadEmployeeDetails({ lavoratoreId: id })]}
                secondBtnVisibile={true}
                secondBtnText="Modifica"
                secondBtnTwBg="bg-afm-box-dark-gray"
                secondBtnTwFocusBg="bg-afm-box-dark-gray[0.7]"
                secondBtnTwTextColor="text-white"
                secondBtnActions={[openEditModal()]}
            />
            <LayoutDetailContainer>
                {/* Skeleton. */}
                {isFetching && <OgranicoDetailSkeleton />}
                {/* Tabs if not fetching. */}
                {!isFetching && !isError && isSuccess &&
                    <Tabs
                        categories={categories}
                        tabDefaultIndex={defaultTabIndex}
                        onChange={(index) => {
                            const categoriesNames = Object.keys(categories);
                            const currentCategory = (categoriesNames[index] || '').trim().toLowerCase();
                            dispatch(reset());
                            setCurrentCategory(currentCategory);
                        }}
                    />
                }
                {/* If Modal window is open, then render. */}
                {/* {(corsoModalStatus.modalIsOpen && modalComponent != null) && <>
                    {modalComponent}
                </>} */}
                {/* If Modal window is open, then render. */}
                {employeeInfo && modalIsOpen && <OrganicoModal editMode={true} data={
                    {
                        id,
                        employeeName: employeeInfo.nome,
                        employeeSurname: employeeInfo.cognome,
                        employeeJob: employeeInfo.mansione,
                        employeeTitle: employeeInfo.qualifica,
                        employeeType: employeeInfo.tipo_dipendente,
                        employeeCf: employeeInfo.codice_fiscale,
                        employeeBirthDay: (employeeInfo.data_nascita || '').split('/').length > 2 ? employeeInfo.data_nascita.split('/')[0] : '',
                        employeeBirthMonth: (employeeInfo.data_nascita || '').split('/').length > 2 ? employeeInfo.data_nascita.split('/')[1] : '',
                        employeeBirthYear: (employeeInfo.data_nascita || '').split('/').length > 2 ? employeeInfo.data_nascita.split('/')[2] : '',
                        employeeHiringDay: (employeeInfo.data_assunzione || '').split('/').length > 2 ? employeeInfo.data_assunzione.split('/')[0] : '',
                        employeeHiringMonth: (employeeInfo.data_assunzione || '').split('/').length > 2 ? employeeInfo.data_assunzione.split('/')[1] : '',
                        employeeHiringYear: (employeeInfo.data_assunzione || '').split('/').length > 2 ? employeeInfo.data_assunzione.split('/')[2] : '',
                        employeeNationality: employeeInfo.nazionalita,
                        employeeBirthPlace: employeeInfo.luogo_nascita,
                        employeeBirthPlaceProvince: employeeInfo.provincia_nascita
                    }
                } />}
                {corsoModalStatus.modalIsOpen && <CorsoModal id={id} />}
                {documentoModalStatus.modalIsOpen && <DocumentoModal id={id} />}
                {visitaModalStatus.modalIsOpen && <VisitaModal id={id} />}
                {/* Error message if data call failed. */}
                {isError && !isSuccess && (
                    <div className="flex items-center justify-center my-14">
                        <p className="text-xl">...Recupero dei dati fallito</p>
                    </div>
                )}
            </LayoutDetailContainer>
        </LayoutDetail>
    )
}
