// React.
import { useState, useEffect, Fragment } from "react"
// Router.
//import { Outlet } from "react-router-dom"
//import SubHeader from "../../base/SubHeader"
// Redux.
import { useDispatch, useSelector } from "react-redux"
// OrganicoSlice.
import {
    cleanUpEmployeeInfo,
    cleanUpEmployeeCourses,
    cleanUpEmployeeExams,
    cleanUpEmployeeDocs,
    getOrganico,
    organicoSelector,
    downloadOrganico,
} from "../../features/generale/organico/OrganicoSlice"
// OrganicoModalSlice.
import {
    organicoModalSelector,
    openModal,
} from "../../features/generale/organico/OrganicoModalSlice"
// UI.
import LayoutDetail from "../LayoutDetail"
import LayoutDetailContainer from "../LayoutDetailContainer"
import Grid from "../../base/Grid"
import SearchBox from "../../base/SearchBox"
import Tooltip from "../../base/Tooltip"
import Pillow from "../../base/Pillow"
import Button from "../../base/Button"
import OrganicoModal from "../generale/OrganicoModal"
// Icons.
import {
    PluSm,
    Printer
} from "../../base/Icons"
// SearchSlice.
import { searchSelector, setSearchList, reset } from "../../features/search/SearchSlice"

/**
 *
 * @dev Lavoratori Component.
 * 
 * @function Lavoratori
 *
 * @returns JSX.Element
 *
 */
export default function Lavoratori() {
    const dispatch = useDispatch()

    // gridItems State.
    const [gridItems, setGridItems] = useState([])
    // gridHeaderTitleItems State.
    const [gridHeaderTitleItems, setGridHeaderTitleItems] = useState([])

    // organicoSlice State.
    const {
        organicoItems,
        isFetching,
        isSuccess,
        isError,
        //errorMsg
    } = useSelector(organicoSelector)

    // visuraModalSlice State.
    const { modalIsOpen, modalIsSucces } = useSelector(organicoModalSelector);

    // searchSlice State.
    const { searchResults, searchList, searchQuery } = useSelector(searchSelector)

    // Status Class enums can be grouped as static members of a class.
    class Status {
        // Create new instances of the same class as static attributes.
        //
        // compliant namespace.
        static compliant = new Status("compliant", "Conforme")
        // nonCompliant namespace.
        static nonCompliant = new Status("non-compliant", "Non conforme")
        // waiting namespace.
        static waiting = new Status("waiting", "In attesa di verifica")

        constructor(value, message) {
            this.value = value
            this.message = message
        }
    }

    // Enums can be accesss by using namespaced assignments.
    // this makes it semantically clear that "compliant" is a "Status"
    const compliant = Status.compliant
    const nonCompliant = Status.nonCompliant
    //const waiting = Status.waiting;

    /**
     *
     * @const Circle
     *
     * @dev Circle UI component.
     *
     * @param { string } message
     *
     * @returns JSX.Element
     *
     */
    const Circle = (props) => {
        /**
         * getStatus function.
         * It return the TailwindCSS class token as the corresponding status.
         */
        const getStatus = (status) => {
            switch (status) {
                case "compliant":
                    return "bg-afm-box-success-green"

                case "non-compliant":
                    return "bg-afm-box-error-red"

                case "waiting":
                    return "bg-afm-box-waiting-yellow"

                default:
                    return "bg-afm-box-light-gray"
            }
        }
        return (
            <Tooltip message={props.message} twBackground="bg-afm-box-blue">
                <div
                    className={`
                         rounded-full 
                         h-3 
                         w-3 
                         ${getStatus(props.status)}`}
                ></div>
            </Tooltip>
        )
    }

    /**
     *
     * Component side effects.
     *
     */
    useEffect(() => {
        dispatch(getOrganico())
        /*
         *
         * Clean up the employee info
         * to have always fresh data to populate child routes components.
         *
         */
        dispatch(cleanUpEmployeeInfo())
        /*
         *
         * Clean up the employee courses
         * to have always fresh data to populate child routes components.
         *
         */
        dispatch(cleanUpEmployeeCourses())
        /*
         *
         * Clean up the employee exams
         * to have always fresh data to populate child routes components.
         *
         */
        dispatch(cleanUpEmployeeExams())
        /*
         *
         * Clean up the employee docs
         * to have always fresh data to populate child routes components.
         *
         */
        dispatch(cleanUpEmployeeDocs())
    }, [dispatch, modalIsSucces])


    useEffect(() => {
        const items = [];

        const emptySearch = (searchQuery || '').trim() === "";
        const searchQueryResults = emptySearch ? searchList : searchResults

        if (searchQueryResults.length < 1) {
            setGridItems(items)
        } else {
            searchQueryResults.map((organico) => {
                const gridItem = [
                    {
                        text: "",
                        additionalText: "",
                        twColSpan: "col-span-1",
                        twAdditionalClass: "place-self-center",
                        component: {},
                    },
                    {
                        text: "Nome e Cognome",
                        additionalText: "",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "",
                        component: "",
                    },
                    {
                        text: "Mansione",
                        additionalText: "",
                        twColSpan: "col-span-3",
                        twAdditionalClass: "",
                        component: {},
                    },
                    {
                        text: "",
                        additionalText: "",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "text-white",
                        component: {},
                    },
                    {
                        text: "",
                        additionalText: "",
                        twColSpan: "col-span-2",
                        twAdditionalClass: "justify-self-end",
                        component: {},
                    },
                ]

                // Item 0.
                gridItem[0].component = (
                    <Circle
                        status={organico.attivo === "Si" ? compliant.value : nonCompliant.value}
                        message={
                            organico.attivo === "Si" ? compliant.message : nonCompliant.message
                        }
                    />
                )
                // Item 1.
                gridItem[1].text = `${organico.cognome} ${organico.nome}`
                gridItem[1].additionalText = organico.codice_fiscale
                // Item 2.
                gridItem[2].component = (
                    <Pillow text={organico.mansione} twBackground="bg-afm-box-light-gray" />
                )
                // Item 3.
                gridItem[3].component = (
                    <Fragment>
                        {organico.tipo_dipendente ? (
                            <Pillow
                                text={organico.tipo_dipendente}
                                twBackground="bg-afm-box-dark-gray"
                            />
                        ) : null}
                    </Fragment>
                )
                // Item 4.
                gridItem[4].component =
                    <Fragment>
                        <Button
                            text="dettaglio"
                            twTextColor="text-white"
                            twBackground="bg-afm-box-blue"
                            twFocusBG="focus:bg-afm-box-blue/[0.5]"
                            navigateTo={`dettaglio-lavoratore-${organico.id_box_lavoratori}`}
                        />
                    </Fragment>

                // Fill the list.
                items.push(gridItem)
                // Set grid items.
                return setGridItems(items)
            })
        }

        // Set grid header titles.
        setGridHeaderTitleItems([
            {
                title: "",
                twColSpan: "col-span-1",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Nome e C.F.",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Mansione",
                twColSpan: "col-span-3",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "Tipologia dipendente",
                twColSpan: "col-span-2",
                twAdditionalClass: "",
                component: "",
            },
            {
                title: "",
                twColSpan: "col-span-2 justify-self-end",
                twAdditionalClass: "",
                component: <SearchBox placeholder={"Cerca lavoratore"} />,
            },
        ])
    }, [
        searchList,
        searchQuery,
        searchResults,
        organicoItems,
        compliant.value,
        nonCompliant.value,
        compliant.message,
        nonCompliant.message,
    ])

    /**
     *
     *  if can get visura items list correctly
     *  then is possible to init and configure the search feature.
     *
     */
    useEffect(() => {
        if (isSuccess) {
            console.log('success');
            // if organicoItems are been fetched then call setSearchList giving:
            // list, search key and an additional list of search key.
            dispatch(
                setSearchList({
                    dataItems: organicoItems,
                    searchIndex: Object.keys(organicoItems.length ? organicoItems[0] : [''])[0],
                    additionalIndex: [
                        'nome',
                        'cognome',
                        'codice_fiscale'
                    ]
                })
            )
        }
    }, [
        dispatch,
        organicoItems,
        isFetching,
        isSuccess,
        isError
    ]);
    useEffect(() => {
        dispatch(reset());
    }, []);

    useEffect(() => { }, [isFetching, isSuccess, isError])

    /**
     *
     * Component render.
     *
     */
    return (
        <LayoutDetail
            title="Lavoratori"
            firstBtnVisibile={true}
            firstBtnText="Stampa"
            firstBtnIcon={<Printer />}
            firstBtnTwBg="bg-white"
            firstBtnTwTextColor="text-black"
            firstBtnActions={[downloadOrganico({})]}
            secondBtnVisibile={false}
            secondBtnText="Aggiungi Lavoratore"
            secondBtnIcon={<PluSm />}
            secondBtnTwBg="bg-afm-box-dark-gray"
            secondBtnTwFocusBg="bg-afm-box-dark-gray[0.7]"
            secondBtnTwTextColor="text-white"
            secondBtnActions={[openModal()]}
        >
            {/*
             <SubHeader
                 subHeaderTitle="Lavoratori"
                 needFirstBreadCrumb={true}
                 firstBtnVisibile={true}
                 firstBtnText="stampa"
                 firstBtnIcon={<Printer />}
                 firstBtnTwBg="bg-white"
                 firstBtnTwFocusBg="focus:bg-white/[0.7]"
                 firstBtnTwTextColor="text-black"
                 secondBtnVisibile={true}
                 secondBtnText="Aggiungi lavoratore"
                 secondBtnIcon={<PluSm />}
                 secondBtnTwBg="bg-afm-box-dark-gray"
                 secondBtnTwFocusBg="focus:bg-afm-box-dark-gray/[0.7]"
                 secondBtnTwTextColor="text-white"
                 secondBtnActions={[openModal()]}
             />
             */}
            <LayoutDetailContainer>
                {/* Skeleton. */}
                {isFetching && <OrganicoSkeleton />}

                {!isFetching && !isError && isSuccess && (
                    <Grid
                        twGridTemplateColumns="grid-cols-10"
                        headerTitleItems={gridHeaderTitleItems}
                        items={gridItems}
                    />
                )}

                {/* If Modal window is open, then render. */}
                {modalIsOpen && <OrganicoModal />}

                {isError && !isSuccess && (
                    <div className="flex items-center justify-center my-14">
                        <p className="text-xl">...Recupero dei dati fallito</p>
                    </div>
                )}
            </LayoutDetailContainer>
        </LayoutDetail>
    )
}

/**
 *
 * @dev Organico skeleton Component.
 * 
 * @function OrganicoSkeleton
 *
 * @returns JSX.Element
 *
 */
function OrganicoSkeleton() {
    return (
        <div className="animate-pulse">
            <div className="flex flex-col">
                <header
                    className="
                         h-20 
                         flex 
                         space-x-4 
                         items-center 
                         justify-items-start 
                         font-semibold"
                >
                    <div className="h-10 w-full bg-inherit rounded"></div>
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                    <div className="h-10 w-full bg-inherit rounded"></div>
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                    <div className="h-10 w-full bg-inherit rounded"></div>
                    <div className="h-10 w-full bg-inherit rounded"></div>
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                    <div className="h-10 w-full bg-inherit rounded"></div>
                    <div className="h-10 w-full bg-inherit rounded"></div>
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </header>
                <span className="flex-grow mb-2 border-t border-gray-400"></span>
                <div
                    className="
                         flex 
                         space-x-4 
                         group my-1 p-1 
                         rounded-md 
                         items-center 
                         justify-center 
                         justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                         flex 
                         space-x-4 
                         group my-1 p-1 
                         rounded-md 
                         items-center 
                         justify-center 
                         justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                         flex 
                         space-x-4 
                         group my-1 p-1 
                         rounded-md 
                         items-center 
                         justify-center 
                         justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                         flex 
                         space-x-4 
                         group my-1 p-1 
                         rounded-md 
                         items-center 
                         justify-center 
                         justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                         flex 
                         space-x-4 
                         group my-1 p-1 
                         rounded-md 
                         items-center 
                         justify-center 
                         justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
                <div
                    className="
                         flex 
                         space-x-4 
                         group my-1 p-1 
                         rounded-md 
                         items-center 
                         justify-center 
                         justify-items-start"
                >
                    <div className="h-10 w-full bg-neutral-300 rounded"></div>
                </div>
            </div>
        </div>
    )
}

/*
export default function Lavoratori() {
    return (
        <LayoutDetail title="Lavoratori">
            <SubHeader
                subHeaderTitle="Lavoratori"
                needFirstBreadCrumb={true}
                firstBtnVisibile={true}
                firstBtnText="stampa"
                firstBtnIcon={<Printer />}
                firstBtnTwBg="bg-white"
                firstBtnTwFocusBg="focus:bg-white/[0.7]"
                firstBtnTwTextColor="text-black"
                secondBtnVisibile={true}
                secondBtnText="Aggiungi lavoratore"
                secondBtnIcon={<PluSm />}
                secondBtnTwBg="bg-afm-box-dark-gray"
                secondBtnTwFocusBg="focus:bg-afm-box-dark-gray/[0.7]"
                secondBtnTwTextColor="text-white"
                //secondBtnActions={[openModal()]}
            />

            <Outlet />
        </LayoutDetail>
    )
}
*/
